<template>
	<div v-if="items && items.length" class="swiper-banner">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="(item, index) of items" :key="index">
				<a v-if="item.LINK" :href="item.LINK" target="_blank">
					<v-img :src="item.PREVIEW_PICTURE"/>
				</a>
				<v-img v-else :src="item.PREVIEW_PICTURE"/>
			</div>
		</div>
	</div>
</template>

<script>
//import {Navigation, Swiper, Autoplay} from "swiper";
import {Autoplay, Swiper} from "swiper";
import 'swiper/swiper.min.css';

let swiper;

export default {
    name: "global-banner",
	props: {
		items: {
			type: Array,
			default: null
		}
	},
    data() {
        return {
        }
    },
	mounted() {
		swiper = new Swiper('.swiper-banner', {
			freeMode: true,
			modules: [Autoplay],
			slidesPerView: 1,
			spaceBetween: 0,
			autoplay: true
		});

	},
}
</script>

<style lang="scss">
.banners {
	.swiper-banner {
		width: 100%;
		box-sizing: border-box;
		overflow: hidden;
	}
}
</style>